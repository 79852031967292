<template>
  <div>
    <modal-edit-invoice-address
      v-if="showEditInvoiceAddress"
      :showModal="showEditInvoiceAddress"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="!isLocked"
      :editFunction="setShowEditInvoiceAddress"
      :editLabel="$t('Components.Reservation.InvoiceAddress.Link_Edit')"
    >
      <template v-slot:title>{{
        $t('Components.Reservation.InvoiceAddress.Section_Title')
      }}</template>
    </ui-section-header>

    <table class="table is-fullwidth">
      <tbody>
        <tr>
          <td>
            {{ $t('Components.Reservation.ReservationDetail.Table_Attn') }}
          </td>
          <td>
            <span>{{ reservation.InvoiceAddress.SendTo }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{
              $t(
                'Components.Reservation.ReservationDetail.Table_InvoiceEmail'
              )
            }}
          </td>
          <td>
            <span>{{ reservation.InvoiceAddress.Email }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{
              $t('Components.Reservation.ReservationDetail.Table_PONumber')
            }}
          </td>
          <td>
            <span>{{ reservation.InvoiceAddress.PoNumber }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.ReservationDetail.Table_Address') }}
          </td>
          <td>
            <span>{{ reservation.InvoiceAddress.Address }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{
              $t('Components.Reservation.ReservationDetail.Table_PostalCode')
            }}
          </td>
          <td>
            <span>{{ reservation.InvoiceAddress.Postalcode }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.ReservationDetail.Table_City') }}
          </td>
          <td>
            <span>{{ reservation.InvoiceAddress.City }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.ReservationDetail.Table_Country') }}
          </td>
          <td>
            <span>{{ reservation.InvoiceAddress.Country }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.ReservationDetail.Table_Phone') }}
          </td>
          <td>
            <span>{{ reservation.InvoiceAddress.PhoneNumber }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import ModalEditInvoiceAddress from '@/components/Reservations/ModalEditInvoiceAddress'

export default {
  components: {
    'modal-edit-invoice-address': ModalEditInvoiceAddress,
  },

  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      showEditInvoiceAddress: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    setShowEditInvoiceAddress() {
      this.showEditInvoiceAddress = true
    },

    hideModal() {
      this.showEditInvoiceAddress = false
    },
  },
}
</script>

<style></style>
