<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalEditInvoiceAddress.Header_ModalHeader')
    "
    :isSaving="isSaving"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-borderless is-fullwidth">
        <tbody>
          <tr>
            <td>Send to</td>
            <td>
              <input
                type="text"
                v-model="mReservation.InvoiceAddress.SendTo"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <input
                type="text"
                v-model="mReservation.InvoiceAddress.Email"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>PO nr</td>
            <td>
              <input
                type="text"
                v-model="mReservation.InvoiceAddress.PoNumber"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              <input
                type="text"
                v-model="mReservation.InvoiceAddress.Address"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Postal code</td>
            <td>
              <input
                type="text"
                v-model="mReservation.InvoiceAddress.Postalcode"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>City</td>
            <td>
              <input
                type="text"
                v-model="mReservation.InvoiceAddress.City"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td>
              <input
                type="text"
                v-model="mReservation.InvoiceAddress.Country"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>
              <input
                type="text"
                v-model="mReservation.InvoiceAddress.PhoneNumber"
                class="input"
              />
            </td>
          </tr>
          <tr v-if="mReservation.ReservationSetIds.length > 0">
            <td>Change all in set</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': changeAllInSet === false }">
                    <a @click="changeAllInSet = false">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': changeAllInSet === true }">
                    <a @click="changeAllInSet = true">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      changeAllInSet: false,
      isSaving: false,
      isSavingError: false,
      formFields: [],
      mReservation: null,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.mReservation = JSON.parse(JSON.stringify(this.reservation))
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    saveReservation() {
      let self = this
      // Do saving then close modal
      if (!self.isSaving) {
        this.isSaving = true

        reservationProvider.methods
          .saveReservationInvoiceAddress(
            self.reservation.Id,
            self.mReservation.InvoiceAddress,
            self.changeAllInSet
          )
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Reservation data updated!',
              })

              this.onClickCancel()
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
